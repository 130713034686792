import React from 'react';
import PropTypes from 'prop-types';
import ArrowLink from '../../../../elements/ArrowLink';
import ExternalLink from '../../../../elements/Link/ExternalLink';
import styles from './styles.module.css';

const NavLink = ({ href, text, ...props }) => (
  <li className={styles.navLink} {...props}>
    <ArrowLink as={ExternalLink} href={href} text={text} />
  </li>
);

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavLink;
