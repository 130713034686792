import { connect } from 'react-redux';
import Toolbar from '../components/core/Toolbar';

const mapStateToProps = ({ menu }) => ({ mobileMenuVisible: menu.mobileMenuVisible });

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch({ type: 'TOGGLE_MOBILE_MENU' }),
});

const ToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toolbar);
export default ToolbarContainer;
