import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import ExternalLink from '../../../../elements/Link/ExternalLink';
import TabLink from '../../../../elements/Link/TabLink';

const SocialLink = ({
  href, icon: T, className = '', ...props
}) => (
  <li className={`${styles.socialLink} ${className}`} {...props}>
    <ExternalLink as={TabLink} href={href}>
      <T className={styles.socialIcon} />
    </ExternalLink>
  </li>
);

SocialLink.defaultProps = {
  className: '',
};

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SocialLink;
