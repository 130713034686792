import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const FacebookIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="-1893 1418.8 8.3 16" {...props}>
    <path d="M-1884.7 1419.1c-.5-.1-1.2-.1-2.1-.3-1.1 0-2 .3-2.7 1s-1 1.6-1 2.8v2.1h-2.5v2.8h2.5v7.3h2.9v-7.3h2.4l.4-2.8h-2.8v-1.8c0-.4.1-.8.3-1s.5-.3 1.1-.3h1.5v-2.5z" />
  </SVG>
);

export default FacebookIcon;
