import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Input = ({ className, ...props }) => (
  <input className={`${styles.input} ${className}`} {...props} />
);

Input.defaultProps = {
  className: '',
};

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
