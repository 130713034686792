import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const ButtonArrowIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="-631 476 16 9" {...props}>
    <path d="M-619.7 484.7l-1-.9 2.7-2.6h-12.1v-1.3h12l-2.6-2.6.9-1 3.3 3.3.9.9z" />
  </SVG>
);

export default ButtonArrowIcon;
