import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styles from './styles.module.css';

const Toolbar = ({ mobileMenuVisible, toggleMobileMenu }) => {
  const classes = mobileMenuVisible ? styles.cross : styles.burger;

  return (
    <div className={styles.toolbar}>
      <Helmet>
        <body className={mobileMenuVisible ? styles.noScroll : ''} />
      </Helmet>
      <div
        role="button"
        tabIndex={0}
        onClick={toggleMobileMenu}
        onKeyPress={toggleMobileMenu}
        className={classes}
      >
        <span />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  mobileMenuVisible: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};

export default Toolbar;
