import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NavLinks = ({ className = '', children, ...props }) => (
  <nav className={`${styles.navLinks} ${className}`} {...props}>
    <ul>{children}</ul>
  </nav>
);

NavLinks.defaultProps = {
  className: '',
};

NavLinks.propTypes = {
  className: PropTypes.string,
};

export default NavLinks;
