import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ExternalLink = ({
  as: T, className, children, ...props
}) => (
  <T className={`${styles.link} ${className}`} {...props}>
    {children}
  </T>
);

ExternalLink.defaultProps = {
  as: 'a',
  className: '',
};

ExternalLink.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ExternalLink;
