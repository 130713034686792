import React from 'react';
import styles from './styles.module.css';

const SVG = ({ className = '', viewBox = '0 0 32 32', ...props }) => (
  <svg
    viewBox={viewBox}
    className={`${styles.svg} ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  />
);

export default SVG;
