import React from 'react';
import ButtonArrowIcon from '../../../elements/Icons/ButtonArrowIcon';
import styles from './styles.module.css';

export default ({ as: T, text, ...props }) => (
  <T {...props}>
    <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
    <ButtonArrowIcon className={styles.icon} />
  </T>
);
