import React from 'react';
import styles from './styles.module.css';
import ToolbarContainer from '../../../containers/ToolbarContainer';
import MainNavigation from '../../navigation/MainNavigation';
import MobileNavContainer from '../../../containers/MobileNavContainer';
import ExternalLink from '../../../elements/Link/ExternalLink';
import BMLogo from '../BMLogo';

const Header = () => (
  <div className={styles.header}>
    <div className={styles.container}>
      <ExternalLink href="https://britishmuseum.org/">
        <BMLogo />
      </ExternalLink>
      <div className={styles.wrapper}>
        <ToolbarContainer />
      </div>
    </div>
    <MainNavigation />
    <MobileNavContainer />
  </div>
);

export default Header;
