/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint jsx-a11y/label-has-for: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import DarkButton from '../../../../elements/DarkButton';
import Input from '../../../../elements/Input';
import CallToAction from '../../CallToAction';

const NewsletterForm = ({ className = '', ...props }) => (
  <div className={`${styles.newsletterForm} ${className}`} {...props}>
    <form method="post" action="https://britishmuseum.msgfocus.com/s/">
      <div className={styles.controls}>
        <input type="hidden" name="_account_id" value="1552" />
        <input type="hidden" name="_table_id" value="8" />
        <input type="hidden" name="_list_id" value="126" />
        <input type="hidden" name="_dedupe" value="1" />
        <input type="hidden" name="_static_update" value="1" />
        <input type="hidden" name="_email_field" value="8.email" />
        <input type="hidden" name="_rp" />
        <label htmlFor="email" className={styles.inputLabel}>
          <span className={styles.inputLabelText}>Newsletter</span>
        </label>
        <Input
          className={styles.input}
          id="email"
          type="email"
          required
          name="email"
          size="30"
          maxLength="64"
          placeholder="Enter your email address"
        />
        <CallToAction
          className={styles.submitButton}
          type="submit"
          as={DarkButton}
          text="Sign Up"
        />
      </div>
    </form>
  </div>
);

NewsletterForm.defaultProps = {
  className: '',
};

NewsletterForm.propTypes = {
  className: PropTypes.string,
};

export default NewsletterForm;
