/* eslint-disable */

import React from 'react';
import FacebookIcon from '../../../elements/Icons/FacebookIcon';
import InstagramIcon from '../../../elements/Icons/InstagramIcon';
import TwitterIcon from '../../../elements/Icons/TwitterIcon';
import YoutubeIcon from '../../../elements/Icons/YoutubeIcon';
import TabLink from '../../../elements/Link/TabLink';
import SocialLink from './SocialLink';
import SocialLinks from './SocialLinks';
import NewsletterForm from './NewsletterForm';
import NavLink from './NavLink';
import NavLinks from './NavLinks';
import Address from './Address';
import Section from './Section';
import styles from './styles.module.css';
import Container from '../Container';

const Footer = () => (
  <div className={styles.footer}>
    <Container>
      <Section className={styles.section1}>
        <SocialLinks>
          <SocialLink href="https://www.facebook.com/britishmuseum" icon={FacebookIcon} />
          <SocialLink href="https://www.instagram.com/britishmuseum/" icon={InstagramIcon} />
          <SocialLink href="https://twitter.com/britishmuseum" icon={TwitterIcon} />
          <SocialLink href="https://www.youtube.com/user/britishmuseum" icon={YoutubeIcon} />
        </SocialLinks>
        <NewsletterForm />
      </Section>
      <Section className={styles.section2}>
        <NavLinks>
          <NavLink href="http://www.britishmuseum.org/about_us.aspx" text="About" />
          <NavLink href="http://www.britishmuseum.org/about_us/news_and_press.aspx" text="Press" />
          <NavLink
            href="http://www.britishmuseum.org/about_this_site/footer_components/contact_us.aspx"
            text="Contact us"
          />
          <NavLink
            href="http://www.britishmuseum.org/about_this_site/footer_components/faqs.aspx"
            text="FAQs"
          />
          <NavLink
            href="http://www.britishmuseum.org/about_this_site/footer_components/terms_of_use.aspx"
            text="Legal"
          />
        </NavLinks>
        <Address>
          <span>The British Museum</span>
          <span>Great Russell Street</span>
          <span>London WC1B 3DG</span>
          <span>United Kingdom</span>
        </Address>
      </Section>

      <Section className={styles.section3}>
        <TabLink
          style={{ color: '#000' }}
          href="http://www.britishmuseum.org/PDF/Modern%20Slavery%20Act%20Transparency%20Statement%202015-16.pdf"
        >
          Modern Slavery Act Statement
        </TabLink>
        <span>© The Trustees of the British Museum</span>
      </Section>
    </Container>
  </div>
);

export default Footer;
