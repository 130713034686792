import React from 'react';
import PropTypes from 'prop-types';
import ButtonArrowIcon from '../Icons/ButtonArrowIcon';
import styles from './styles.module.css';

const ArrowLink = ({
  className = '', text, as: T, ...props
}) => (
  <T className={`${styles.arrowLink} ${className}`} {...props}>
    <span>{text}</span>
    <ButtonArrowIcon />
  </T>
);

ArrowLink.defaultProps = {
  className: '',
};

ArrowLink.propTypes = {
  as: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ArrowLink;
