import React from 'react';

const NavigationList = () => (
  <ul>
    <li>
      <a href="http://www.britishmuseum.org/visiting.aspx">Visiting</a>
    </li>
    <li>
      <a href="http://www.britishmuseum.org/whats_on.aspx">Exhibitions and Events</a>
    </li>
    <li>
      <a href="http://www.britishmuseum.org/research.aspx">Research</a>
    </li>
    <li>
      <a href="http://www.britishmuseum.org/learning.aspx">Learning</a>
    </li>
    <li>
      <a href="http://www.britishmuseum.org/membership.aspx">Membership</a>
    </li>
    <li>
      <a href="http://www.britishmuseum.org/support_us.aspx">Support</a>
    </li>
    <li>
      <a href="https://blog.britishmuseum.org/">Blog</a>
    </li>
  </ul>
);

export default NavigationList;
