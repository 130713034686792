import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const YoutubeIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="-1262.1 945.1 16 11.4" {...props}>
    <path d="M-1246.3 954s-.2 1.1-.6 1.6c-.6.6-1.3.6-1.6.7-2.2.2-5.6.2-5.6.2s-3.4 0-5.6-.2c-.3 0-1 0-1.6-.7-.5-.5-.6-1.6-.6-1.6s-.2-1.3-.2-2.6v-1.2c0-1.3.2-2.6.2-2.6s.2-1.1.6-1.6c.6-.6 1.4-.6 1.8-.7 1.3-.1 5.4-.2 5.4-.2s3.4 0 5.6.2c.3 0 1 0 1.6.7.5.5.6 1.6.6 1.6s.2 1.3.2 2.6v1.2c0 1.3-.2 2.6-.2 2.6zm-9.5-5.3v4.5l4.3-2.3-4.3-2.2z" />
  </SVG>
);

export default YoutubeIcon;
