import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import NavigationList from '../NavigationList';

const MobileNavigation = ({ mobileMenuVisible }) => {
  const classes = [styles.mobileNav, mobileMenuVisible ? styles.open : null].join(' ');

  return (
    <div className={classes}>
      <NavigationList />
    </div>
  );
};

MobileNavigation.propTypes = {
  mobileMenuVisible: PropTypes.bool.isRequired,
};

export default MobileNavigation;
