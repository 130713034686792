import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Button = ({
  as: T, children, className, ...props
}) => (
  <T className={`${styles.button} ${className}`} {...props}>
    {children}
  </T>
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  as: 'button',
};

export default Button;
