import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './styles.module.css';

const DarkButton = ({ className, ...props }) => (
  <Button className={`${styles.darkButton} ${className}`} {...props} />
);

DarkButton.defaultProps = {
  className: '',
};

DarkButton.propTypes = {
  className: PropTypes.string,
};

export default DarkButton;
