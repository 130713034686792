import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const TwitterIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="-1893 1419.9 16 13.2" {...props}>
    <path d="M-1877 1421.7c-.6.3-1.3.5-1.8.2.7-.4 1.1-1 1.4-1.8-.7.4-1.4.7-2.1.8-.7-.7-1.5-1-2.4-1s-1.6.4-2.3 1-1 1.4-1 2.3c0 .3 0 .5.1.8-1.4-.1-2.6-.4-3.8-1s-2.2-1.4-3-2.4c-.3.6-.4 1.1-.4 1.7s.1 1.1.4 1.6c.3.5.7.9 1.1 1.2-.5 0-1-.1-1.5-.4 0 .8.2 1.5.7 2.1s1.1.9 1.9 1.1c-.3.1-.6.1-.9.1-.2 0-.4-.1-.6-.1.3.6.6 1.1 1.2 1.6s1.2.7 1.9.7c-1.2.9-2.6 1.4-4.1 1.4h-.8c1.5 1 3.2 1.5 5 1.5 1.2 0 2.3-.2 3.3-.6s1.8-.9 2.6-1.5 1.3-1.3 1.9-2.1c.6-.8.9-1.6 1.2-2.5.3-.8.4-1.7.4-2.6v-.4c.6-.5 1.1-1 1.6-1.7z" />
  </SVG>
);

export default TwitterIcon;
