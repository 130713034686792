import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const InstagramIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="0 0 800 800" {...props}>
    <g>
      <path d="M400.003 72.068c106.804 0 119.45.408 161.633 2.332 38.997 1.78 60.178 8.298 74.274 13.774 18.673 7.257 31.996 15.926 45.993 29.923 14 13.997 22.67 27.317 29.923 45.99 5.477 14.097 11.993 35.277 13.77 74.275 1.925 42.18 2.333 54.83 2.333 161.636s-.41 119.457-2.334 161.636c-1.778 39-8.295 60.18-13.77 74.274-7.255 18.67-15.925 31.993-29.924 45.993-13.997 13.998-27.32 22.668-45.993 29.925-14.098 5.476-35.278 11.993-74.275 13.77-42.175 1.928-54.82 2.332-161.633 2.332-106.815 0-119.46-.404-161.636-2.33-38.997-1.78-60.178-8.297-74.275-13.772-18.67-7.257-31.995-15.927-45.992-29.923-13.997-14-22.664-27.323-29.92-45.992-5.477-14.094-11.997-35.274-13.775-74.274-1.924-42.18-2.332-54.83-2.332-161.636s.41-119.455 2.333-161.636c1.778-38.998 8.298-60.178 13.774-74.275 7.256-18.673 15.923-31.994 29.92-45.99 13.996-13.998 27.323-22.667 45.992-29.924 14.1-5.478 35.28-11.996 74.277-13.776 42.182-1.924 54.83-2.332 161.636-2.332m0-72.07c-108.636 0-122.258.458-164.92 2.407-42.577 1.942-71.654 8.706-97.096 18.593-26.304 10.222-48.61 23.898-70.847 46.135C44.903 89.37 31.223 111.68 21.004 137.98c-9.893 25.45-16.654 54.522-18.596 97.1C.46 277.746.003 291.365.003 399.997c0 108.636.457 122.255 2.405 164.917 1.942 42.58 8.703 71.654 18.596 97.1 10.22 26.303 23.9 48.61 46.136 70.85C89.376 755.1 111.683 768.778 137.987 779c25.442 9.887 54.52 16.647 97.096 18.593 42.663 1.945 56.285 2.408 164.92 2.408 108.633 0 122.255-.46 164.917-2.406 42.578-1.945 71.654-8.706 97.097-18.593 26.304-10.22 48.61-23.897 70.85-46.135 22.237-22.24 35.914-44.547 46.136-70.85 9.89-25.445 16.65-54.52 18.594-97.1 1.947-42.662 2.404-56.28 2.404-164.917 0-108.633-.455-122.252-2.402-164.917-1.942-42.576-8.703-71.65-18.594-97.1-10.222-26.3-23.898-48.61-46.135-70.845C710.627 44.897 688.32 31.22 662.017 21c-25.443-9.888-54.52-16.652-97.098-18.594-42.662-1.95-56.284-2.41-164.917-2.41" />
      <path d="M400.003 194.594c-113.442 0-205.406 91.96-205.406 205.403S286.56 605.4 400.003 605.4c113.44 0 205.403-91.96 205.403-205.403s-91.964-205.403-205.403-205.403m0 338.735c-73.64 0-133.335-59.695-133.335-133.334s59.696-133.333 133.335-133.333 133.332 59.694 133.332 133.333S473.642 533.33 400.003 533.33m261.52-346.85c0 26.506-21.488 47.997-48.002 47.997-26.506 0-47.996-21.49-47.996-47.998 0-26.516 21.49-48.004 47.998-48.004 26.515 0 48.003 21.488 48.003 48.002" />
    </g>
  </SVG>
);

export default InstagramIcon;
