import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Address = ({ className = '', ...props }) => (
  <p className={`${styles.address} ${className}`} {...props} />
);

Address.defaultProps = {
  className: '',
};

Address.propTypes = {
  className: PropTypes.string,
};

export default Address;
