import React from 'react';
import styles from './styles.module.css';
import NavigationList from '../NavigationList';

const MainNavigation = () => (
  <div className={styles.navigation}>
    <div className={styles.container}>
      <NavigationList />
    </div>
  </div>
);

export default MainNavigation;
