import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const SocialLinks = ({ className = '', ...props }) => (
  <ul className={`${styles.socialLinks} ${className}`} {...props} />
);

SocialLinks.defaultProps = {
  className: '',
};

SocialLinks.propTypes = {
  className: PropTypes.string,
};

export default SocialLinks;
